
.group-creation-page {

  max-width: 800px;
  min-height: 800px;
  margin: auto;
  margin-bottom: 10px;
  background: white;
  padding: 20px;
  border-width: 10px;
  border-radius: 10px;
  border-color: white;
  border-style: solid;

  .ant-layout-content.content {
    background: white;
    height: 100%;
    padding-top: 0;
  }
}

@primary-color: #775CD9;