.mobile-page {

  min-height: 100vh;

  .header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .logo {
      max-height: 100%;
      padding: 10px;
    }

    .app-name {
      color: white;
      margin: 0;
    }
  }

  .content {
    padding: 10px;
    height: 100%;
    background: white;
    text-align: center;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-evenly;

    .welcome {
      font-size: 1.5em;
    }

  }

  .mobile-button {
    font-size: 1.5em;
    font-weight: 600;
    height: 60px;
    width: 80vw;
    border-radius: 10px;
    margin-top: 10px;
  }


}

@primary-color: #775CD9;