.base-page {

  &.ant-layout {
    min-height: 100vh;
  }

  @media print {
    .left-menu {
      display: none;
    }
  }

  .header {
    color: #E6E6E6;
    background: #E6E6E6;

    .header-container {
      display: flex;
      justify-content: space-between;
      height: 100%;

      .logo {
        color: black;
        height: 100%;

        img {
          height: calc(100% - 10px);
          object-fit: contain;
        }
      }

      .ant-menu.ant-menu-root {
        background: #E6E6E6;
        color: #555151;
      }
    }
  }

  .left-menu > .ant-layout-sider-children {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;

    .logo {
      height: 45px;
      align-items: center;

      img {
        height: 40px;
      }

      .app-name {
        padding-left: 10px;
        font-weight: bold;
      }
    }
  }

  .app-content {
    display: flex;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;

    .app-filter {

    }

    .app-main-content {
      flex-grow: 2;
      background: #E6E6E6;
      width: 100%;
      padding-top: 5vh;
      @media (min-width: 1024px) {
        padding-left: 7%;
        padding-right: 7%;
      }
    }
  }


}

@primary-color: #775CD9;