.main-filter {
  background-color: white;
  width: 100%;
  text-align: left;
  padding: 10px;
  margin: 0;


  .ant-col {
    padding-left: 8px;
  }

  .select {
    width: 100%;
  }

  .range-picker {
    width: 100%;
    height: 38px;
  }

  .apply {
    height: 38px;
    width: 100%;
  }

  @media print {
    .apply-cell {
      display: none;
    }
  }
}


@primary-color: #775CD9;