.side-bar {

  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  &.with-border {
    border-top: 2px solid @primary-color;
  }


  .sidebar {
    transition: flex 0.3s ease-in-out;
    background-color: white;
    flex: 0;
    overflow: hidden;

  }

  .sidebar.open {
    display: initial;
    flex: 2;
    border-left: 2px solid #e5e5e5;
  }

  .content {
    flex: 10;
    transition: flex 0.3s ease-in-out;
    background-color: transparent;
    padding: 0;
  }


  .content.open {
  }
}

@primary-color: #775CD9;