
.add-group-mobile-page {

  text-align: center;
  padding: 10px;
  margin: auto;

  .welcome {
    font-size: 1.5em;
  }

  .location-info {
    font-size: 1.3em;
  }

  .add-group-form {
    label[for="root-title"] {
      font-size: 2em;
      font-weight: bold;
      color: @primary-color
    }

    label[for="root_penalties__title"], label[for="root_members__title"] {
      font-size: 1.2em;
      font-weight: bold;
      color: #2f2f2f;
    }

    .item-root_members {
      border: 2px solid @primary-color;
      border-radius: 20px;
      margin-bottom: 8px;
      padding: 8px;

      .idx {
        font-weight: bold;

      }

      .ant-form-item {
        margin-bottom: 0;
      }

    }

    .field-description {
      margin-top: -20px;
      margin-left: 2px;
      color: #2f2f2f;
      text-align: left;
      font-size: 0.8em;
    }
  }
}

@primary-color: #775CD9;